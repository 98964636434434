import fetch from '@/apis/request'
import { formdata2obj } from '@/utils/utils'

export default class Apis {
    // 分页查询台账
    static findAllList(formData) {
        return fetch.get('/pc/securityCheck/page', {
            params: formdata2obj(formData)
        })
    }

    // 查询单个台账
    static getDetails(id) {
        return fetch.get('/pc/securityCheck/getDetails', {
            params: formdata2obj({id})
        })
    }

    // 分页查询汇总台账
    static pageTotalData(formData) {
        return fetch.get('/pc/securityCheck/pageTotalData', {
            params: formdata2obj(formData)
        })
    }
}
