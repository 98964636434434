<template>
  <div class="security-inspection-ledger flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span>安全检查</span>
      </div>
      <div class="tp-right flex-center-v">
        <el-input v-model="searchData.departmentName" placeholder="所属项目/单位名称"  clearable size="small" style="margin-right:15px;width: 200px"></el-input>
        <el-date-picker
            size="small"
            v-model="timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 400px;margin-right: 15px;">
        </el-date-picker>
        <el-select v-model="searchData.securityCheckStatus" placeholder="检查状态" size="small" style="width:160px;margin-right:15px;">
          <el-option label="全部" value=""></el-option>
<!--          <el-option label="无需整改" value="0"></el-option>-->
          <el-option label="待整改" value="1"></el-option>
          <el-option label="待复查" value="2"></el-option>
          <el-option label="合格" value="3"></el-option>
        </el-select>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" border tooltip-effect="light myTooltips">
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">
<!--            <el-button type="text" @click="viewInfo(scope.row)">{{ scope.$index + 1 }}</el-button>-->
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="securityCheckStatus" label="检查结果" show-overflow-tooltip align="left" width="160">
          <template slot-scope="{row}">
            <div class="flex-center-v">
<!--              <span v-if="row.securityCheckStatus == '0'" class="dian wu"></span>-->
              <span v-if="row.securityCheckStatus == '1'" class="dian daiz"></span>
              <span v-if="row.securityCheckStatus == '2'" class="dian daif"></span>
              <span v-if="row.securityCheckStatus == '3'" class="dian he"></span>
<!--              <el-button v-if="row.securityCheckStatus == '0'" type="text" @click="viewInfo(row)" class="line">无需整改</el-button>-->
              <el-button v-if="row.securityCheckStatus == '1'" type="text" @click="viewInfo(row)" class="line">待整改</el-button>
              <el-button v-if="row.securityCheckStatus == '2'" type="text" @click="viewInfo(row)" class="line">待复查</el-button>
              <el-button v-if="row.securityCheckStatus == '3'" type="text" @click="viewInfo(row)" class="line">合格</el-button>
<!--              <div v-if="row.securityCheckStatus == '0'" class="line">无需整改</div>-->
<!--              <div v-if="row.securityCheckStatus == '1'" class="line">待整改</div>-->
<!--              <div v-if="row.securityCheckStatus == '2'" class="line">待复查</div>-->
<!--              <duv v-if="row.securityCheckStatus == '3'" class="line">合格</duv>-->
              <div v-if="row.ifLimit" class="cq">超期</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="所属单位/项目名称" show-overflow-tooltip align="left"></el-table-column>
        <el-table-column prop="checkName" label="检查人" align="left">
          <template slot-scope="{row}">
            <div>{{ row.checkName }}</div>
            <div>检查时间:{{ row.checkTime }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="checkContent" label="隐患信息"  align="left" width="200">
          <template slot-scope="{row}">
            <div class="flex">
              <div class="flex-center">
                <el-tag class="infoTag" v-if="row.accidentDanger==1"  effect="dark" size="mini" type="warning">一般</el-tag>
                <el-tag class="infoTag" v-else type="danger" effect="dark" size="mini">重大</el-tag>
              </div>
              <div class="checkContentInfo overText">{{row.checkContent}}</div>
            </div>
            <div class="flex">
              <el-tag class="infoTag" type="info" size="small">
                <i class="el-icon-picture">{{ getImages(row.scenePhotos).length||0 }}</i>
              </el-tag>
              <el-tag class="infoTag" type="info" size="small">
                <i class="el-icon-map-location overText1">{{ row.dutyAreaName }}</i>
              </el-tag>
              <el-tag class="infoTag" type="info"  size="small">
                {{ row.dangerLevel | num2Live }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subcontractUnitName" label="分包单位" align="left"></el-table-column>
        <el-table-column prop="rectifyUserName" label="整改人" align="left" width="120"></el-table-column>
        <el-table-column prop="reviewUserName" label="复查人" align="left" width="120"></el-table-column>
        <el-table-column prop="checkTypeName" label="检查类型" align="left"></el-table-column>
<!--        <el-table-column label="操作" align="center"></el-table-column>-->
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="total > searchData.pageSize"
          background
          :current-page.sync="searchData.pageCurrent"
          :page-size="searchData.pageSize"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
    <el-dialog title="查看详情" :visible.sync="showInfo" width="40%">
      <div class="info">
        <div class="title flex-center-v"><span>隐患记录</span><div class="slh"></div></div>
        <div class="listBox">
          <div class="tipTitle">
            <div><span>检查结果：{{ detailsInfo.securityCheckStatus | num2Status}}</span><span>责任区域：{{ detailsInfo.dutyAreaName }}</span></div>
            <div><span>检查人：{{ detailsInfo.checkName }}</span><span>检查时间：{{ detailsInfo.checkTime }}</span></div>
          </div>
          <div class="box">
            <div class="item"><span class="tit">项目名称:</span><div class="con">{{ detailsInfo.departmentName }}</div></div>
            <div class="item"><span class="tit">隐患类别:</span><div class="con">{{ detailsInfo.dangerCategoryName }}</div></div>
            <div class="item"><span class="tit">隐患明细:</span><div class="con">{{ detailsInfo.checkContent }}</div></div>
            <div class="item"><span class="tit">补充说明:</span><div class="con">{{ detailsInfo.explains }}</div></div>
            <div class="item"><span class="tit">检查类型:</span><div class="con">{{ detailsInfo.checkTypeName }}</div></div>
            <div class="item"><span class="tit">隐患级别:</span><div class="con">{{ detailsInfo.dangerLevel | num2Live }}</div></div>
            <div class="item"><span class="tit">分包单位:</span><div class="con">{{ detailsInfo.subcontractUnitName }}</div></div>
            <div class="item"><span class="tit">事故隐患:</span><div class="con">{{ detailsInfo.accidentDanger>1 ? '重大' : '一般' }}</div></div>
            <div class="item"><span class="tit">整改人:</span><div class="con">{{ detailsInfo.rectifyUserName }}</div></div>
            <div class="item"><span class="tit">通知人:</span><div class="con">{{ detailsInfo.noticeUserNameStr }}</div></div>
            <div class="item"><span class="tit">整改时限:</span><div class="con">{{ detailsInfo.rectifyLimitTime }}</div></div>
            <div class="item"><span class="tit">整改要求:</span><div class="con">{{ detailsInfo.rectifyRequirement }}</div></div>
            <div class="item" style="width: 100%;">
              <span class="tit">相关照片:</span>
              <div class="con">
                <el-image
                    v-for="(img, ind) in getImages(detailsInfo.scenePhotos)"
                    :preview-src-list="[...getImages(detailsInfo.scenePhotos)]"
                    :src="img"
                    :key="ind + 'aaa'"
                    style="width: 150px;height: 160px;margin:0 5px"
                ></el-image>
             </div>
            </div>
          </div>
        </div>
        <template v-if="rectifyManageDtoList.length > 0">
          <div v-for="(item, index) in rectifyManageDtoList" :key="index + 'tempalte'">
            <div class="title flex-center-v"><span>整改记录</span><div class="slh"></div></div>
            <div class="listBox">
              <div class="tipTitle">
                <div><span>整改状态：{{ item.rectifyStatus ? '已整改' : '未整改' }}</span><span>整改时间：{{ item.rectifyTime }}</span></div>
                <div><span>整改人：{{ item.rectifyUserName }}</span></div>
              </div>
              <div class="box">
                <div class="item" style="width: 100%;"><span class="tit">说明:</span><div class="con">{{ item.explains }}</div></div>
                <div class="item" style="width: 100%;">
                  <span class="tit">相关照片:</span>
                  <div class="con">
                    <el-image
                        v-for="(img, ind) in getImages(item.rectifyPhotos)"
                        :preview-src-list="[...getImages(item.rectifyPhotos)]"
                        :src="img"
                        :key="ind + 'aaa'"
                        style="width: 150px;height: 160px;margin:0 5px"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="reviewManageDtoList.length > 0">
          <div v-for="(item, index) in reviewManageDtoList" :key="index + 'tempalte'">
          <!--  aaa  -->
          <div v-if="reviewManageDtoList[index]" class="title flex-center-v"><span>复查记录</span><div class="slh"></div></div>
          <div class="listBox">
            <div class="tipTitle">
              <div><span>复查状态：{{ reviewManageDtoList[index].reviewStatus==1?'合格':'不合格' }}</span><span>复查时间：{{ reviewManageDtoList[index].reviewTime }}</span></div>
              <div><span>复查人：{{ reviewManageDtoList[index].reviewUserName }}</span></div>
            </div>
            <div class="box">
              <div class="item" style="width: 100%;"><span class="tit">说明:</span><div class="con">{{ reviewManageDtoList[index].explains }}</div></div>
              <div class="item" style="width: 100%;">
                <span class="tit">相关照片:</span>
                <div class="con">
                  <el-image
                      v-for="(img, ind) in getImages(reviewManageDtoList[index].reviewPhotos)"
                      :preview-src-list="[...getImages(reviewManageDtoList[index].reviewPhotos)]"
                      :src="img"
                      :key="ind + 'aaa'"
                      style="width: 150px;height: 160px;margin:0 5px"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import apis from './apis';

export default {
  name: "security-inspection-ledger",

  components: {},

  props: {},

  filters: {
    num2Status(val) {
      // if (val == '0') return '无需整改'
      if (val == '1') return '待整改'
      if (val == '2') return '待复查'
      if (val == '3') return '合格'
    },
    num2Live(val) {
      if (val == 1) return '一级'
      if (val == 2) return '二级'
      if (val == 3) return '三级'
      if (val == 4) return '四级'
    }
  },

  data() {
    return {
      tableList: [],
      searchData: {
        checkStartDate: '',
        departmentName:'',
        checkEndDate: '',
        securityCheckStatus: '',
        pageCurrent: 1,
        pageSize: 10
      },
      timer: '',
      total: 0,
      showInfo: false,
      detailsInfo: {},
      rectifyManageDtoList: [],
      reviewManageDtoList: []
    }
  },

  computed: {
    ...mapGetters(['companyId'])
  },

  watch: {
    companyId() {
      this.search()
    }
  },

  mounted() {
    if(this.companyId) this.getTableList()
  },

  methods: {
    async getTableList() {
      this.searchData.departmentCode = this.companyId
      const result = await apis.findAllList(this.searchData)
      this.tableList = result.list
      this.total = result.total
    },
    viewInfo(row) {
      apis.getDetails(row.id).then(res => {
        this.detailsInfo = res
        this.rectifyManageDtoList = res.rectifyManageDtoList
        this.reviewManageDtoList = res.reviewManageDtoList
        this.$nextTick(() => {
          this.showInfo = true
        })
      })
    },
    // 查询
    search() {
      if (this.timer) {
        this.searchData.checkStartDate = this.timer[0]
        this.searchData.checkEndDate = this.timer[1]
      } else {
        this.searchData.checkStartDate = ''
        this.searchData.checkEndDate = ''
      }
      this.searchData.pageCurrent = 1
      this.getTableList().async
      // this.searchData.pageCurrent = 1
      // this.getTableList().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    },
    getImages(str) {
      if (!str) return []
      const imgs = str.split(',')
      return imgs
    },
    getPreviewImgList(index) {
      const images = this.detailsInfo.scenePhotos.split(',')
      let arr = [];
      for (let i = 0; i < images.length; i++) {
        arr.push(images[i + index]);
        if (i + index >= images.length - 1) { // 为了取后边小值（采用绝对值）
          index = 0 - (i + 1);
        }
      }
      return arr;
    }
  }
}
</script>

<style lang="stylus" scoped>
.security-inspection-ledger {
  height 100%
  padding 10px
  .info {
    max-height 800px
    overflow-y auto
    margin-top -20px
    padding-bottom 20px
    .title {
      color #56A6FF
      font-size 16px
      padding 30px 0 10px
      .slh {
        margin-left 10px
        flex 1
        height 1px
        border-bottom 1px dashed #56A6FF
      }
    }
    .listBox {
      .tipTitle {
        padding 10px 15px
        background #EBECF0
        color #575F6C
        font-size 15px
        >div {
          display flex
          margin-bottom 3px
          >span {
            width 40%
            text-align left
            font-weight bold
          }
        }
      }
      .box {
        border 1px solid #dcdcdc
        min-height 200px
        padding 10px 20px
        display flex
        flex-wrap wrap
        align-items flex-start
        .item {
          width 50%
          box-sizing border-box
          min-height 30px
          font-size 15px
          display flex
          align-items flex-start
          >span {
            color #666666
            margin-right 10px
            width 65px
            text-align right
            flex-shrink 0
          }
          >div {
            color #999999
            //text-overflow ellipsis
            //white-space nowrap
            //overflow hidden
          }
        }
      }
    }
  }
  .cq {
    width 40px
    line-height 18px
    text-align center
    margin-left 5px
    background #F56C6C
    color #FFFFFF
    font-size 12px
    border-radius 4px
  }

  .dian {
    width 7px
    height 7px
    border-radius 50%
    margin-right 5px
  }

  .wu .he {
    background #67C23A
  }

  .daiz {
    background #F56C6C
  }

  .daif {
    background #E6A23C
  }
}
.infoTag{
  margin 2px 4px
}
.overText{
  /* 设置宽度，如果文本内容长短不一，可以设置max-width最大宽度显示，小于最大宽度不会打点 */
  width: 120px;
  //display: -webkit-box;
  /* 文本不会换行显示 */
  white-space: nowrap;
  /* 超出盒子部分隐藏 */
  overflow: hidden;
  /* 文本超出的部分打点显示 */
  text-overflow: ellipsis;
}
.overText1{
  /* 设置宽度，如果文本内容长短不一，可以设置max-width最大宽度显示，小于最大宽度不会打点 */
  width: 50px;
  //display: -webkit-box;
  /* 文本不会换行显示 */
  white-space: nowrap;
  /* 超出盒子部分隐藏 */
  overflow: hidden;
  /* 文本超出的部分打点显示 */
  text-overflow: ellipsis;
}
.line{
  text-decoration underline
  text-underline-offset: 5px;//下划线和文字间距
}
</style>
